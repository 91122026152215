import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal, RenderInBody } from 'bv-components';
import { compose } from 'underscore';
import { t } from 'bv-i18n';
import List from './list';
import TeamLink from './two_columns/team_link';

const visiblePlayers = 3;
const sliceFirst = (collection) => collection.slice(0, visiblePlayers);
// This function will take a collection of player and will place
// the selected player (if any) on the last position of the visible
// items of the collection. This way a selected player of a team
// will always be visible into the collapsed list of players
const sortSelected = (collection) => {
  const selected = collection.find((el) => el.selected);
  const collectionWOSelected = collection.filter((el) => !el.selected);
  const selectedPosition = collection.indexOf(selected);

  if (!selected || selectedPosition < visiblePlayers) {
    return collection;
  }

  return [
    ...collectionWOSelected.slice(0, visiblePlayers - 1),
    selected,
    ...collectionWOSelected.slice(visiblePlayers - 1),
  ];
};
class TwoColumns extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      displayModal: false,
      activeGroup: 0,
    };

    this.hideModal = this.hideModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.changeActiveGroup = this.changeActiveGroup.bind(this);
  }

  hideModal() {
    this.setState((prevState) => ({
      ...prevState,
      displayModal: false,
    }));
  }

  showModal() {
    this.setState((prevState) => ({
      ...prevState,
      displayModal: true,
    }));
  }

  changeActiveGroup(activeGroup) {
    this.setState((prevState) => ({
      ...prevState,
      activeGroup,
    }));
  }

  render() {
    const {
      replaceSelections, selections, title, specificInfo,
    } = this.props;
    const { activeGroup, displayModal } = this.state;
    const replaceSelectionsAndClosePopup = (newSelections) => {
      this.hideModal();

      return replaceSelections(newSelections);
    };

    const filterGroup = (collection) => collection
      .filter((el) => el.groupId === activeGroup);

    const currentGroupSelections = filterGroup(selections);
    const firstSelections = compose(
      sliceFirst,
      sortSelected,
      filterGroup,
    );

    return (
      <div className="bb-playerlist bb-market-component">
        <span className="bb-title-component">
          {title}
        </span>

        <div>
          {[0, 1].map((index) => (
            <TeamLink
              groupId={index}
              teamName={specificInfo[`teamName${index}`]}
              activeGroup={activeGroup}
              changeActiveGroup={this.changeActiveGroup}
            />
          ))}
        </div>

        <br />

        <div>
          <List
            selections={firstSelections(selections)}
            replaceSelections={replaceSelections}
          />
        </div>

        {
          displayModal
          && (
          <RenderInBody>
            <Modal icon={false} className="bb-playerlist-modal" iconClosable onCloseClick={this.hideModal}>
              <List
                selections={currentGroupSelections}
                replaceSelections={replaceSelectionsAndClosePopup}
              />
            </Modal>
          </RenderInBody>
          )
        }

        {
          currentGroupSelections.length > visiblePlayers
          && (
          <span className="bb-team-list__item bb-playerlist__viewmore bvs-link" onClick={this.showModal}>
            {t('javascript.bet_builder.app.view_more')}
          </span>
          )
        }
      </div>
    );
  }
}

TwoColumns.propTypes = {
  title: PropTypes.string.isRequired,
  selections: PropTypes.instanceOf(Array).isRequired,
  specificInfo: PropTypes.instanceOf(Object).isRequired,
  replaceSelections: PropTypes.func.isRequired,
};

export default TwoColumns;
