import PropTypes from 'prop-types';

import List from './bb_components/list';
import Picker from './bb_components/picker';
import SegmentedControl from './bb_components/segmented_control';
import Switch from './bb_components/switch';
import Stepper from './bb_components/stepper';
import TwoColumns from './bb_components/two_columns';
import Scroller from './bb_components/scroller';
import ConditionedStepper from './bb_components/conditioned_stepper';
import ConditionedScroller from './bb_components/conditioned_scroller';
import ConditionedScrollerAndStepper from './bb_components/conditioned_scroller_and_stepper';
import HorizontalList from './bb_components/horizontal_list';

/* eslint-disable react/destructuring-assignment */
const BbComponent = (props) => {
  switch (props.type) {
    case 'List':
      return <List {...props} />;
    case 'Picker':
      return <Picker {...props} />;
    case 'SegmentedControl':
      return <SegmentedControl {...props} />;
    case 'Switch':
      return <Switch {...props} />;
    case 'Stepper':
      return <Stepper {...props} />;
    case 'TwoColumns':
      return <TwoColumns {...props} />;
    case 'Scroller':
      return <Scroller {...props} />;
    case 'ConditionedStepper':
      return <ConditionedStepper {...props} />;
    case 'ConditionedScroller':
      return <ConditionedScroller {...props} />;
    case 'ConditionedScrollerAndStepper':
      return <ConditionedScrollerAndStepper {...props} />;
    case 'HorizontalList':
      return <HorizontalList {...props} />;
    default:
      return <List {...props} />;
  }
};

BbComponent.propTypes = {
  type: PropTypes.string.isRequired,
};

export default BbComponent;
