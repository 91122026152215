/* eslint jsx-a11y/label-has-for: off */

import { Component } from 'react';
import PropTypes from 'prop-types';

class ToggleSwitch extends Component {
  toggleSwitch = () => {
    const { replaceSelections } = this.props;
    const selection = this.selectionFor(0).selected ? this.selectionFor(1) : this.selectionFor(0);

    replaceSelections([selection]);
  };

  selectionFor(index) {
    const { selections } = this.props;
    return selections[index];
  }

  switched() {
    return this.selectionFor(1).selected ? 'on' : 'off';
  }

  render() {
    return (
      <div className="bb-toggle-switch bb-market-component">
        <span className="bb-toggle-switch__value">{this.selectionFor(0).title}</span>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="bb-toggle-switch" className="bb-toggle-switch__label">
          <input name="bb-toggle-switch" type="checkbox" data-checked={this.switched()} />
          <span className={`bb-toggle-switch__slider bb-toggle-${this.switched()}`} onClick={this.toggleSwitch} />
        </label>
        <span className="bb-toggle-switch__value">{this.selectionFor(1).title}</span>
      </div>
    );
  }
}

ToggleSwitch.propTypes = {
  selections: PropTypes.instanceOf(Array).isRequired,
  replaceSelections: PropTypes.func.isRequired,
};

export default ToggleSwitch;
