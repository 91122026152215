/* eslint prefer-spread: off */
import _ from 'underscore';

const selectionId = (selection) => selection.id;

const legSelections = (leg) => ({
  selections: _.flatten(Object.values(leg.components)).map(selectionId),
});

export default (legsState, eventId) => {
  const legs = Object.values(legsState.legs);

  return {
    eventId,
    legs: Object.values(legs).map(legSelections),
  };
};
