import { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ContentLayout } from 'bv-components';
import EventContainer from 'BetBuilder/components/event/event_container';
import { setUrlConfigs } from 'BetBuilder/ducks/event';
import { useLocation } from 'react-router';

const App = ({
  setUrlConfigs: setUrlConfigsProp,
}) => {
  const location = useLocation();
  useEffect(() => {
    setUrlConfigsProp(new URLSearchParams(location.search));
  }, []);

  return (
    <ContentLayout>
      <div className="bet-builder-app">
        <EventContainer />
      </div>
    </ContentLayout>
  );
};

App.propTypes = {
  setUrlConfigs: PropTypes.func.isRequired,
};

App.displayName = 'BetBuilderApp';

const mapStateToProps = (state) => state.betBuilder.urlConfigs;

export default connect(mapStateToProps, { setUrlConfigs })(App);
