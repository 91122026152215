import PropTypes from 'prop-types';
import { Modal, RenderInBody } from 'bv-components';
import { t } from 'bv-i18n';

const Info = ({ title, infoMsg, closeCallback }) => (
  <RenderInBody>
    <Modal
      title={title}
      info
      onCloseClick={closeCallback}
      actions={[{ label: t('close'), info: true, onClick: closeCallback }]}
    >
      <p>{infoMsg}</p>
    </Modal>
  </RenderInBody>
);

Info.propTypes = {
  title: PropTypes.string,
  infoMsg: PropTypes.string.isRequired,
  closeCallback: PropTypes.func,
};

Info.defaultProps = {
  title: '',
  closeCallback: () => {},
};

export default Info;
