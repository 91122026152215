import { addMiddleware } from 'redux-dynamic-middlewares';

import App from './components/app';

import eventReducer from './ducks/event';
import composerReducer from './ducks/composer';
import priceReducer from './ducks/price';
import outcomeReducer from './ducks/outcome';
import middleware from './middleware';

const { addReducers } = window.reduxState;

addReducers({
  betBuilder: eventReducer,
  betBuilderComposer: composerReducer,
  betBuilderPrice: priceReducer,
  betBuilderOutcome: outcomeReducer,
});

addMiddleware(
  middleware,
);

export default App;
