import PropTypes from 'prop-types';

const selected = (collection) => collection.find((element) => element.selected);

const Stepper = ({
  selections, disabled, selectionChange, title,
}) => {
  const firstSelection = selections[0];
  const lastSelection = selections.slice(-1)[0];
  const currentSelection = selected(selections) || { title: '-' };
  const currentSelectionIndex = selections.indexOf(currentSelection);
  const previousSelection = selections[currentSelectionIndex - 1] || firstSelection;
  const nextSelection = selections[currentSelectionIndex + 1] || lastSelection;

  const stepperClicked = (selection) => {
    if (disabled || selection === currentSelection) {
      return;
    }

    selectionChange(selection);
  };

  return (
    <div className="bb-goal-number bb-market-component">
      <span className="bb-title-component">
        { title }
      </span>
      <div className={`bb-goal-number__goals ${disabled ? 'bb-disabled' : ''}`}>
        <button className="is-minus-icon is-big" type="button" onClick={() => stepperClicked(previousSelection)} />
        <span className="bb-goal-number__value">{currentSelection.title}</span>
        <button className="is-plus-icon is-big" type="button" onClick={() => stepperClicked(nextSelection)} />
      </div>
    </div>
  );
};

Stepper.propTypes = {
  title: PropTypes.string.isRequired,
  selections: PropTypes.instanceOf(Array).isRequired,
  disabled: PropTypes.bool.isRequired,
  selectionChange: PropTypes.func.isRequired,
};

export default Stepper;
