import buildStorage from 'src/shared/services/storage';

const { loadState, saveState, flushState } = buildStorage({
  storagePrefix: 'betBuilder-composerState',
});

export {
  loadState,
  saveState,
  flushState,
};
