import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { withScrollToTop } from 'bv-hocs';

import Item from './item';

const List = ({ items }) => {
  const { eventId } = useParams();

  return (
    <div className="bvs-card bb-market-list">
      { items.map((item) => <Item {...item} eventId={eventId} />) }
    </div>
  );
};

List.propTypes = {
  items: PropTypes.instanceOf(Array).isRequired,
};

export default withScrollToTop()(List);
