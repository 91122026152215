/* eslint prefer-spread: off */

import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withFold } from 'bv-hocs';
import { isDesktop } from 'bv';
import { useParams } from 'react-router-dom';
import { t } from 'bv-i18n';

import ErrorModal from 'BetBuilder/components/modals/error';
import CreatingOutcomeModal from 'BetBuilder/components/modals/creating_outcome';
import LegContainer from './leg/leg_container';
import PriceContainer from './price/price_container';
import MoreMarkets from './more_markets';

const MIN_LEGS_TO_EXPAND = 3;

/* ------- Helper Presentational Component ------- */
const Legs = (props) => Array.apply(null, { length: props.legsCount }).map((_, legIndex) => (
  <LegContainer
    legIndex={legIndex}
  />
));

const legsAreaClassNames = (props) => classnames('bvs-card bb-results__outcome-list', {
  expanded: !props.folded,
});

const expandButtonClassNames = (props) => classnames('bb-results_expand', {
  expanded: !props.folded,
});

const LegsArea = withFold((props) => {
  const scrollRef = useRef(null);

  useEffect(() => {
    scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
  }, [props.legsCount, props.folded]);

  return (
    <div ref={scrollRef} className={legsAreaClassNames(props)}>
      <div className="bb-composer-legs">
        <Legs {...props} />
      </div>
      <div className="bb-composer-bottomline">
        { useParams().marketId && <MoreMarkets /> }
        {
          !isDesktop() && props.legsCount > MIN_LEGS_TO_EXPAND && (
            <button
              type="button"
              onClick={props.toggleFolded}
              className={expandButtonClassNames(props)}
            />
          )
        }
      </div>
    </div>
  );
}, () => !isDesktop());

/* ------- Presentational Component ------- */

const Composer = (props) => {
  const {
    legsCount,
    limitReached,
    removeLimitReached,
    creatingOutcome,
    creatingOutcomeError,
    removeCreateOutcomeError,
  } = props;

  return (
    <div>
      <div className="bb-results">
        { legsCount > 0 && <LegsArea {...props} /> }
        <PriceContainer />
      </div>
      {
         limitReached && (
         <ErrorModal
           title={t('javascript.bet_builder.app.errors.leg.title')}
           errorMsg={t('javascript.bet_builder.app.errors.leg.body')}
           closeCallback={removeLimitReached}
         />
         )
      }
      {
        creatingOutcome && <CreatingOutcomeModal />
      }
      {
        creatingOutcomeError && (
        <ErrorModal
          title={t(`javascript.bet_builder.app.errors.outcome.${creatingOutcomeError.errorCode}.title`)}
          errorMsg={t(`javascript.bet_builder.app.errors.outcome.${creatingOutcomeError.errorCode}.body`)}
          closeCallback={removeCreateOutcomeError}
        />
        )
      }
    </div>
  );
};

Composer.propTypes = {
  legsCount: PropTypes.instanceOf(Array).isRequired,
  limitReached: PropTypes.bool,
  creatingOutcome: PropTypes.bool,
  removeLimitReached: PropTypes.func.isRequired,
  creatingOutcomeError: PropTypes.instanceOf(Object).isRequired,
  removeCreateOutcomeError: PropTypes.func.isRequired,
};

Composer.defaultProps = {
  limitReached: false,
  creatingOutcome: false,
};

export default Composer;
