import { Route, Switch, Redirect } from 'react-router-dom';

import Navigation from 'BetBuilder/components/navigation/primary/list_container';
import SecondaryNavigation from 'BetBuilder/components/navigation/secondary/list_container';
import MarketContainer from 'BetBuilder/components/market/market_container';

/* ------- Helper Presentational Components ------- */

const MarketsArea = () => (
  <Switch>
    {
      /*
        Have tried to use a single rout with a regexp for market and legs
        with no luck. Have tried things like (/market/:id/leg/:legIndex)
      */
    }
    { /* Navigation */ }
    <Route exact path="/bet-builder/:eventId" component={Navigation} />
    <Route exact path="/bet-builder/:eventId/markets/:id" component={SecondaryNavigation} />
    { /* Market Display */ }
    <Route exact path="/bet-builder/:eventId/market/:id" component={MarketContainer} />
    <Route exact path="/bet-builder/:eventId/market/:id/leg/:legIndex" component={MarketContainer} />
    <Redirect to="/bet-builder/:eventId" />
  </Switch>
);

export default MarketsArea;
