import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { clear, keep } from 'BetBuilder/services/clear_or_keep';
import ClearOrKeep from './clear_or_keep';

const ClearOrKeepContainer = (props) => (
  <ClearOrKeep
    {...props}
  />
);

ClearOrKeepContainer.propTypes = {
  clear: PropTypes.func.isRequired,
  keep: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  clear() {
    clear(dispatch);
  },
  keep() {
    keep(dispatch);
  },
});

export default connect(null, mapDispatchToProps)(ClearOrKeepContainer);
