import PropTypes from 'prop-types';
import _ from 'underscore';
import nullifySelection from 'BetBuilder/services/nullify_selection';
import Slider from './slider';

const selected = (collection) => collection.find(_.property('selected'));

const isNumber = _.negate(_.compose(Number.isNaN, Number));
const titleIsNumber = _.compose(isNumber, _.property('title'));
const allNumbers = _.partial(_.every, _, titleIsNumber);

const splittedSelections = (selections) => {
  if (!allNumbers(selections)) {
    return [[], selections];
  }

  return [
    selections.filter((selection) => Number(selection.title) < 0),
    selections.filter((selection) => Number(selection.title) >= 0),
  ];
};

/* --------------------------------------------------------------------------- */

const Scroller = (props) => {
  const {
    replaceSelections,
    selections,
  } = props;

  const selectionChange = (selection) => replaceSelections([
    selection,
  ]);

  /*
    Build the final selections array for the slider with the null selection ('-')
    into it and the selections (if possible) splitted into the ones that will go
    to the left of the null selection and to the right
  */
  const nullScrollerSelection = nullifySelection({
    ...selections[0],
    // If there's no selected selection the null one will be selected
    selected: selected(selections) === undefined,
  });

  const [leftSelections, rightSelections] = splittedSelections(selections);

  const sliderSelections = [
    ...leftSelections,
    nullScrollerSelection,
    ...rightSelections,
  ];
  // --------------------------------------------------------------------

  return (
    <div className="bb-slider-selections">
      <Slider
        selections={sliderSelections}
        selectionChange={selectionChange}
        selectedId={selected(sliderSelections).id}
      />
    </div>
  );
};

Scroller.propTypes = {
  selections: PropTypes.instanceOf(Array).isRequired,
  replaceSelections: PropTypes.func.isRequired,
};

export default Scroller;
