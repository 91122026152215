import PropTypes from 'prop-types';
import { isEqual } from 'underscore';

import Switch from './switch';
import Stepper from './stepper';

const selected = (collection) => collection.find((element) => element.selected);

const ConditionedStepper = ({
  selections, specificInfo, title, replaceSelections,
}) => {
  const switchSelections = selections.filter((selection) => selection.groupId === 0);
  const switchSelectedSelection = selected(switchSelections);
  const stepperSelections = selections.filter((selection) => selection.groupId === 1);
  const stepperSelectedSelection = selected(stepperSelections);
  const tagToDisable = specificInfo.disableWhen;
  const stepperDisabled = (switchSelection) => isEqual(switchSelection.tags, tagToDisable);

  // The stepper is calling the callback function with the selection wrapped
  // into an array, that's the reason we're destructuring here
  const switchSelectionChange = ([selection]) => {
    const stepperIsGonnaBeDisabled = stepperDisabled(selection);
    const stepperSelection = stepperSelectedSelection || stepperSelections[0];

    const newSelections = (stepperIsGonnaBeDisabled
      && [selection])
      || [selection, stepperSelection];

    replaceSelections(newSelections);
  };
  const stepperSelectionChange = (selection) => {
    replaceSelections([
      switchSelectedSelection,
      selection,
    ]);
  };

  return (
    <div>
      <Switch
        selections={switchSelections}
        replaceSelections={switchSelectionChange}
      />
      <Stepper
        title={title}
        selections={stepperSelections}
        selectionChange={stepperSelectionChange}
        disabled={stepperDisabled(switchSelectedSelection)}
      />
    </div>
  );
};

ConditionedStepper.propTypes = {
  title: PropTypes.string.isRequired,
  selections: PropTypes.instanceOf(Array).isRequired,
  specificInfo: PropTypes.instanceOf(Object).isRequired,
  replaceSelections: PropTypes.func.isRequired,
};

export default ConditionedStepper;
