/* eslint prefer-spread: off */

/* ********* TEMPLATES AND SELECTIONS PREPROCESSING  *********** */

// Process the templates object and create another one where the keys
// are the different placeholders we initially had but sorted alphabetically
// and separated with ::
//
//
// {
//   'period.90::score.dash::score.dash::team': '<team> to win',
//   'period.half::score.dash::score.dash::team': '<team> to win in the <period.half>'
//   ...
// }
//
const templateObjectsFrom = (rawTemplates) => Object.keys(rawTemplates).reduce((acc, key) => {
  const selectors = key
    .match(/[^<>]+/g) // Split between < >
    .sort() // Sort alphabetically
    .reverse()
    .join('::'); // Join with ::

  return {
    ...acc,
    [selectors]: rawTemplates[key],
  };
}, {});

// Add 'candidateTags' property to the selection that will contain
// every possible tag for this selection sorted from more to less
// specific
//
// [
//   {
//     title: 'Malaga FC',
//     tags: [ 'team', 'away' ],
//     candidateTags: [ 'team.away', 'team' ]
//   },
//   {
//     title: 'Each Period',
//     tags: [ 'period', 'each' ],
//     candidateTags: [ 'period.each', 'period' ]
//   },
//   ...
// ]
//
const selectionObjectsFrom = (rawSelections) => rawSelections.map((selection) => ({
  ...selection,
  candidateTags: (selection.tags || []).reduce((acc, tag) => [
    ...acc,
    [...acc.slice(-1), tag].join('.'),
  ], []).reverse(),
}));

/* ********* TEMPLATE RETRIEVAL  *********** */

// Perform the cartesian product of an array of arrays.
// With this we generate all the possible combinations of the
// selections candidateTags from more specific to less specific
//
// Convert from:
//
// [
//   [ 'team.away', 'team' ],
//   [ 'period.each', 'period' ],
//   [ 'score.home', 'home' ],
//   [ 'score.away', 'away' ],
// ]
//
// [
//   [ "team.away", "period.each", "score.home", "score.away" ],
//   [ "team.away", "period.each", "score.home", "away" ],
//   [ "team.away", "period.each", "home", "score.away" ],
//   [ "team.away", "period.each", "home", "away" ],
//   ...
//   [ "team", "period", "score.home", "score.away" ],
//   [ "team", "period", "score.home", "away" ],
//   [ "team", "period", "home", "score.away" ],
//   [ "team", "period", "home", "away" ]
// ]
//
const flatten = (arr) => [].concat.apply([], arr);
const cartesianProduct = (sets) => sets
  .reduce((acc, set) => flatten(acc
    .map((x) => set
      .map((y) => [...x, y]))),
  [[]]);

// Retrieve the set of candidate tags from more specific to less specific
// for our given set of selections, once we have them generated and sorted
// we will generate an alphabetically sorted string for each one in order to
// do a direct search over the templates object (hash)
//
const candidateTags = (selections) => cartesianProduct(selections.map((obj) => obj.candidateTags))
  .map((candidate) => candidate
    .sort()
    .reverse()
    .join('::'));

const findTemplate = (templates, selections) => {
  const result = candidateTags(selections).find((candidate) => templates[candidate]);

  // If not result is found we will return a 'null' template with an
  // empty string as value
  return result ? templates[result] : '';
};

/* ********* TEMPLATE TRANSLATION  *********** */

// Build the regexp for a selection that will be used to try to replace
// a template placeholder with the selection title
//
const regexpFor = (selection) => new RegExp(selection.candidateTags.map((cand) => `<${cand}>`).join('|'));

export const selectionTitleWrapper = (title) => `<span class="bb-market-added-row__selection">${title}</span>`;
const presentedTitle = (selection) => {
  if (selection.id === -1) {
    return '<span class="bb-market-added-row__value"></span>';
  }

  return selectionTitleWrapper(selection.title);
};

// Perform the translation of a template value with the given set
// of selections.
//
// It will start from the template value as the partial translation
// and will iterate over our selections building a regexp that will
// be used to try to replace a placeholder from the partial translation
// with the selection title
//
const translateTemplate = (templateValue, selections) => selections
  .reduce((partialTranslation, selection) => partialTranslation
    .replace(
      regexpFor(selection),
      presentedTitle(selection),
    ), templateValue);

/* ********* MAIN METHOD  *********** */

export default (rawTemplates, rawSelections) => {
  const selections = selectionObjectsFrom(rawSelections);
  const templates = templateObjectsFrom(rawTemplates);

  // Find the most specific template 'value' that fits our selections
  const template = findTemplate(
    templates,
    selections,
  );

  // Translate the template 'value' with our selections
  return translateTemplate(
    template,
    selections,
  );
};
