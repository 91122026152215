import { connect } from 'react-redux';
import { keep } from 'BetBuilder/services/clear_or_keep';

import { removeLeg } from 'BetBuilder/ducks/composer';

import Leg from './leg';

/* ------- Redux Container Component ------- */

const LegContainer = (props) => <Leg {...props} />;

const mapStateToProps = () => (state, ownProps) => ({
  description: state.betBuilderComposer.legs[ownProps.legIndex].description,
  marketId: state.betBuilderComposer.legs[ownProps.legIndex].marketId,
  success: state.betBuilderPrice.triggeredByLeg === ownProps.legIndex,
  legsCount: state.betBuilderComposer.legs.length,
  // We are checking against legIndex + 1 because Botsphere BE returns the
  // failing leg index starting at index 1 instead of 0
  error: state.betBuilderPrice.error.legs.includes(ownProps.legIndex + 1),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  removeLeg() {
    dispatch(removeLeg(ownProps.legIndex));
  },
  navigateToMarket() {
    keep(dispatch);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LegContainer);
