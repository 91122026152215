import PropTypes from 'prop-types';

const Icon = ({ icon }) => (
  <>
    <i className={`bb-market-list__${icon}`} />
    <span className="is-plus-icon is-big" />
  </>
);

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
};

export default Icon;
