import PropTypes from 'prop-types';

const SegmentedControl = ({ title, selections, replaceSelections }) => (
  <div className="bb-period-selector bb-market-component">
    <span className="bb-title-component">{title}</span>
    <div className="bb-period-selector__periods">
      {selections.map((selection) => (
        <div
          className={`bvs-link bb-period-selector__period ${selection.selected ? 'active' : ''}`}
          onClick={() => !selection.selected && replaceSelections([selection])}
        >
          {selection.title}
        </div>
      ))}
    </div>
  </div>
);

SegmentedControl.propTypes = {
  title: PropTypes.string.isRequired,
  selections: PropTypes.instanceOf(Array).isRequired,
  replaceSelections: PropTypes.func.isRequired,
};

export default SegmentedControl;
