import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { t } from 'bv-i18n';

const ClearOrKeep = ({ clear, keep }) => {
  const history = useHistory();
  const { eventId } = useParams();
  const onClear = () => {
    clear();
    history.push(`/bet-builder/${eventId}`);
  };
  const onKeep = () => {
    keep();
    history.push(`/bet-builder/${eventId}`);
  };

  return (
    <div className="bvs-card lb-clear-keep">
      <div className="lb-clear-keep__button bvs-button-alternative is-secondary" onClick={onClear}>
        { t('javascript.bet_builder.app.clear') }
      </div>
      <div className="lb-clear-keep__button bvs-button is-info" onClick={onKeep}>
        { t('javascript.bet_builder.app.keep') }
      </div>
    </div>
  );
};

ClearOrKeep.propTypes = {
  clear: PropTypes.func.isRequired,
  keep: PropTypes.func.isRequired,
};

export default ClearOrKeep;
