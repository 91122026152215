/* eslint no-unused-vars: 'off', arrow-parens: 'off', no-param-reassign: 'off' */

// The above eslint rules are disabled because we wanted to follow The
// usual notation that can be found on the docs when writing middlewares

import { saveState } from './services/local_storage';
import {
  ADD_LEG_SUCCESS,
  REMOVE_LEG_SUCCESS,
  EMPTY_LEGS_SUCCESS,
  ADD_SELECTIONS_SUCCESS,
} from './ducks/composer';

const betBuilderMiddleware = store => next => action => {
  next(action);

  switch (action.type) {
    case ADD_LEG_SUCCESS:
    case REMOVE_LEG_SUCCESS:
    case EMPTY_LEGS_SUCCESS:
    case ADD_SELECTIONS_SUCCESS: {
      const state = store.getState();

      const eventId = Object.keys(state.betBuilder.event)[0];

      if (eventId) {
        saveState(eventId, state.betBuilderComposer.legs);
      }

      break;
    }
    default:
      break;
  }
};

export default betBuilderMiddleware;
