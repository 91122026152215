/* eslint prefer-spread: off, max-len: off */

import nullifySelection from 'BetBuilder/services/nullify_selection';
import { uniq, pluck } from 'underscore';

const filterGroupSelections = (groupId, selections) => selections
  .filter((selection) => selection.groupId === groupId);
const filterDefaultSelections = (defaultSelectionIds, selections) => selections
  .filter((selection) => defaultSelectionIds.includes(selection.id));
const componentHasEmptyDefaults = (component) => ['Picker'].includes(component.type);

// TODO: Refactor and Test
export default (component, selections) => {
  const firstAvailableGroup = uniq(pluck(selections, 'groupId'))[0];

  const groupDefaultSelections = Array.apply(null, { length: component.selectionsToAdd }).map((_, groupId) => {
    const groupSelections = filterGroupSelections(firstAvailableGroup + groupId, selections);
    const defaultSelections = filterDefaultSelections(component.defaultSelections, groupSelections);

    // Return group defaults if any
    if (defaultSelections.length > 0) {
      return defaultSelections;
    }
    // Return null selection if mandatory (and it didnt have a default)
    if (component.mandatory) {
      return [nullifySelection(groupSelections[0])];
    }
    // Return empty if the component should have empty  defaults (special components)
    if (componentHasEmptyDefaults(component)) {
      return [];
    }
    // Return the first selection
    return [groupSelections[0]];
  });

  return [].concat.apply([], groupDefaultSelections);
};
