import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { fetchEvent, removeEvent } from 'BetBuilder/ducks/event';
import { removeComposer } from 'BetBuilder/ducks/composer';
import { removePrice } from 'BetBuilder/ducks/price';
import { removeOutcome } from 'BetBuilder/ducks/outcome';

import { getDisplayClearOrKeep } from 'BetBuilder/selectors/outcome';
import { getLocked } from 'BetBuilder/selectors/composer';

import Event from './event';

/* eslint-disable react/destructuring-assignment */
const EventContainer = (props) => {
  const { eventId, outcomeId } = useParams();
  const history = useHistory();
  const { event, cleanState } = props;

  useEffect(() => {
    props.fetchEvent({ eventId, outcomeId });
    history.replace(`/bet-builder/${eventId}`);
    return () => cleanState();
  }, []);

  return <Event {...props} event={event[eventId]} />;
};

EventContainer.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  fetchEvent: PropTypes.func.isRequired,
  cleanState: PropTypes.func.isRequired,
  displayClearOrKeep: PropTypes.bool.isRequired,
  uiLocked: PropTypes.bool.isRequired,
  setOutcomeId: PropTypes.func.isRequired,
  event: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string.isRequired,
  })).isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.betBuilder.loading,
  event: state.betBuilder.event,
  displayClearOrKeep: getDisplayClearOrKeep(state),
  uiLocked: getLocked(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchEvent(eventData) {
    dispatch(fetchEvent(eventData));
  },
  cleanState() {
    dispatch(removePrice());
    dispatch(removeOutcome());
    dispatch(removeComposer());
    dispatch(removeEvent());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EventContainer);
