import PropTypes from 'prop-types';

import nullifySelection from 'BetBuilder/services/nullify_selection';

import Slider from './slider';
import Switch from './switch';

const selected = (collection) => collection.find((element) => element.selected);

/* --------------------------------------------------------------------------- */

const ConditionedScroller = ({ selections, replaceSelections }) => {
  const switchSelections = selections.filter((selection) => selection.groupId === 0);
  const switchSelectedSelection = selected(switchSelections);
  const scrollerSelections = selections.filter((selection) => selection.groupId === 1);
  const stepperSelectedSelection = selected(scrollerSelections);

  const nullScrollerSelection = nullifySelection({
    ...scrollerSelections[0],
    // If there's no selected selection the null one will be selected
    selected: stepperSelectedSelection === undefined,
  });

  // The stepper is calling the callback function with the selection wrapped
  // into an array, that's the reason we're destructuring here
  const switchSelectionChange = ([selection]) => {
    replaceSelections([
      selection,
      stepperSelectedSelection || nullScrollerSelection,
    ]);
  };
  const scrollerSelectionChange = (selection) => {
    replaceSelections([
      switchSelectedSelection || switchSelections[0],
      selection,
    ]);
  };

  const sliderSelections = [
    nullScrollerSelection,
    ...scrollerSelections,
  ];

  return (
    <div className="bb-slider-selections">
      <Switch
        selections={switchSelections}
        replaceSelections={switchSelectionChange}
      />
      <Slider
        selections={sliderSelections}
        selectionChange={scrollerSelectionChange}
        selectedId={selected(sliderSelections).id}
      />
    </div>
  );
};

ConditionedScroller.propTypes = {
  selections: PropTypes.instanceOf(Array).isRequired,
  replaceSelections: PropTypes.func.isRequired,
};

export default ConditionedScroller;
