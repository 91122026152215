import { normalize, schema } from 'normalizr';

const componentProcessStrategy = (component) => {
  /*
    On this process strategy we will take a look at the selections
    of the component.

    The selections can be a single array or a multidimensional array in
    case we need to draw a couple of columns (for instance for players
    of each team or scores for each team)

    What we're doing here is to flatten the multidimensional array in case
    it comes and assign a groupId to each selection so we can take a look
    on the client to which group each selection belongs
  */
  const selections = !Array.isArray(component.selections[0])
    ? component.selections.map((selectionItem) => ({
      ...selectionItem,
      groupId: 0,
    }))
    : []
      .concat(...component.selections
        .map((selectionsArray, index) => selectionsArray
          .map((selectionItem) => ({
            ...selectionItem,
            groupId: index,
          }))));

  return {
    ...component,
    selections,
    // For each component we are also storing its default selection ids if any
    defaultSelections: selections
      .filter((selection) => selection.default)
      .map((selection) => selection.id),
  };
};

export default (rawResponse) => {
  const selectionSchema = new schema.Entity('selections', {}, {
    // This is needed because if the same selection appears on
    // different components on a different group its id will collide
    idAttribute: (value) => `${value.id}-${value.groupId}`,
  });
  const componentSchema = new schema.Entity('components', {
    selections: [selectionSchema],
  }, {
    processStrategy: componentProcessStrategy,
  });
  const marketSchema = new schema.Entity('markets', {
    components: [componentSchema],
  });
  const eventSchema = new schema.Entity('event', {
    markets: [marketSchema],
  });

  return normalize(rawResponse, eventSchema);
};
