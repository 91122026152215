import { mapObject, isEmpty } from 'underscore';

/*
  - stateSelections: All selections in the state so we can use this to perform the look up
  - components: Set of components (set of selections) where we're trying to do the replacement
  - relatedSelections: Set of related selections we're gonna try to replace
*/
export default (stateSelections, components, relatedSelections) => {
  if (isEmpty(relatedSelections)) {
    return components;
  }

  // For each of our components (set of selections)
  return mapObject(components, (selections) => selections.reduce((acc, selection) => {
    // We are gonna iterate through each selection and try to find a related selection
    // for it in the ones provided, if we find any we will add that selection instead of
    // the current one for the component, if there's no related selection we will
    // keep the current selection
    const relatedSelectionId = relatedSelections[selection.tags.toString()];

    const selectionToAdd = (relatedSelectionId
        && stateSelections[`${relatedSelectionId}-${selection.groupId}`])
        || selection;

    return [
      ...acc,
      // The reason why we're destructuring here is that
      // we're adding all the missing fields that have been present
      // into the current selection (componentId, if it's selected or not...)
      // and are missing into the new selection being added if it was
      // a replacement
      {
        ...selection,
        ...selectionToAdd,
      },
      // ---------------------------------------------------
    ];
  }, []));
};
