import { postJSON } from 'bv-fetch';

import urls from './urls';

// If this get more complex extract and refactor
// we want undefined if the price is null because in an object,
// if a property is null, the property still exists in the object
// and a react component receiving that object as props will not consider
// the default value of it. If the property is assigned to undefined, the
// property in the object is removed and when passed as properties it will
// be as it is not present and the default property value will kick in
const present = (response) => {
  if (response.error) {
    return Promise.reject(response.error);
  }

  return response || { price: undefined };
};

export default (backendOutcome) => (
  postJSON(urls.priceUrl(), backendOutcome)
    .then(present)
);
