import { flatten } from 'underscore';
import translationBuilder from './translation_builder';

export default (state, legIndex, components) => {
  const leg = state.legs[legIndex];

  return {
    ...state,
    legs: [
      ...state.legs.slice(0, legIndex),
      {
        ...leg,
        components,
        description: translationBuilder(
          state.templates,
          flatten(Object.values(components)),
        ),
      },
      ...state.legs.slice(legIndex + 1),
    ],
  };
};
