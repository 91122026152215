/* eslint import/prefer-default-export: off */

import { createSelector } from 'reselect';

const getComposerState = (state) => state.betBuilderComposer;
const getCurrentLegIndex = (state, props) => {
  const { legIndex } = props.match.params;

  return legIndex !== '-1'
    ? parseInt(legIndex, 10)
    : Object.keys(state.betBuilderComposer.legs).length - 1;
};

const getLocked = createSelector(
  getComposerState,
  (composerState) => composerState.locked,
);

const makeGetLeg = () => createSelector(
  getComposerState,
  getCurrentLegIndex,
  (composerState, legIndex) => composerState.legs[legIndex],
);

export {
  getLocked,
  makeGetLeg,
};
