import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getRootItems } from 'BetBuilder/selectors/navigation';
import List from '../list';

const ListContainer = (props) => <List {...props} />;

ListContainer.propTypes = {
  items: PropTypes.instanceOf(Array).isRequired,
};

const mapStateToProps = (state) => ({
  items: getRootItems(state),
});

export default connect(mapStateToProps)(ListContainer);
