/* eslint prefer-spread: off */
import { flatten } from 'underscore';

const legValid = (leg) => leg && flatten(Object.values(leg.components))
  .every((selection) => selection.id !== -1);

export default (composerState) => {
  const { legs } = composerState;
  const legsIndex = Object.keys(legs);

  return legsIndex.length > 0 && legsIndex.every((legIndex) => legValid(legs[legIndex]));
};

export {
  legValid,
};
