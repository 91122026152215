/* eslint jsx-a11y/anchor-is-valid: off */
import PropTypes from 'prop-types';

const List = ({ selections, title, replaceSelections }) => (
  <div className="bb-team-list__item bb-market-component">
    <span className="bb-title-component">
      {title}
    </span>

    {
      selections
        .map((selection) => (
          <span
            onClick={() => !selection.selected && replaceSelections([selection])}
            className={`bvs-link bb-team-list__item ${selection.selected ? 'active' : ''}`}
          >
            {selection.title}
          </span>
        ))
    }
  </div>
);

List.propTypes = {
  title: PropTypes.string.isRequired,
  selections: PropTypes.instanceOf(Array).isRequired,
  replaceSelections: PropTypes.func.isRequired,
};

export default List;
