import { connect } from 'react-redux';

import { removeLimitReached } from 'BetBuilder/ducks/composer';
import { removeCreateOutcomeError } from 'BetBuilder/ducks/outcome';
import {
  getCreatingOutcome,
  getCreatingOutcomeError,
} from 'BetBuilder/selectors/outcome';

import Composer from './composer';

/* ------- Redux Container Component ------- */

const ComposerContainer = (props) => <Composer {...props} />;

const mapStateToProps = (state) => ({
  legsCount: state.betBuilderComposer.legs.length,
  limitReached: state.betBuilderComposer.limitReached,
  creatingOutcome: getCreatingOutcome(state),
  creatingOutcomeError: getCreatingOutcomeError(state),
});

const mapDispatchToProps = (dispatch) => ({
  removeLimitReached() {
    dispatch(removeLimitReached());
  },
  removeCreateOutcomeError() {
    dispatch(removeCreateOutcomeError());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ComposerContainer);
