import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Spinner, Icon } from 'bv-components';
import TutorialContainer from 'BetBuilder/components/modals/tutorial/tutorial_container';
import ComposerContainer from 'BetBuilder/components/composer/composer_container';
import MarketsArea from './markets_area';
import ClearOrKeepContainer from './clear_or_keep/clear_or_keep_container';

/* ------- Presentational Component ------- */

const uiLockedClassName = (props) => classnames({
  'bb-disable-ui': props.uiLocked,
});

const Event = (props) => {
  const { loading, event, displayClearOrKeep } = props;

  return (
    loading
      ? <Spinner />
      : (
        <>
          <TutorialContainer />
          <div className={uiLockedClassName(props)}>
            { /* Event Title */ }
            <div className="bb-event-description-wrapper">
              <Icon className="bvs-event-icon is-piu" big />
              <h1 className="bb-event-description">
                { event.description }
              </h1>
            </div>

            { /* Composer */ }
            <Route
              path="/bet-builder/:eventId/:market?/:marketId?/:leg?/:legIndex?"
              component={ComposerContainer}
            />

            { /* Markets */ }
            { displayClearOrKeep ? <ClearOrKeepContainer /> : <MarketsArea {...props} /> }
          </div>
        </>
      )
  );
};

Event.propTypes = {
  loading: PropTypes.bool.isRequired,
  event: PropTypes.shape({
    description: PropTypes.string.isRequired,
  }).isRequired,
  displayClearOrKeep: PropTypes.bool.isRequired,
};

export default Event;
