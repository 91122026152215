import componentDefaultSelections from 'BetBuilder/services/component_default_selections';

const componentSelections = (component, state) => component.selections
  .map((selectionId) => state.selections[selectionId]);

export const buildLeg = (marketId, state) => {
  const marketComponentIds = state.markets[marketId].components;

  return marketComponentIds.reduce((acc, componentId) => {
    const component = state.components[componentId];
    const selections = componentSelections(component, state);

    return {
      ...acc,
      [componentId]: componentDefaultSelections(component, selections),
    };
  }, {});
};

export const buildDefaultLeg = (componentIds, state) => {
  const components = componentIds.map((componentId) => state.components[componentId]);

  return components.reduce((acc, component) => {
    const selections = componentSelections(component, state);

    return {
      ...acc,
      [component.id]: componentDefaultSelections(component, selections),
    };
  }, {});
};
