import PropTypes from 'prop-types';
import { Modal, RenderInBody } from 'bv-components';
import { t } from 'bv-i18n';

const Error = ({ title, errorMsg, closeCallback }) => (
  <RenderInBody>
    <Modal
      className="bb-error-modal"
      title={title}
      danger
      onCloseClick={closeCallback}
      actions={[{ label: t('close'), danger: true, onClick: closeCallback }]}
    >
      <p>{errorMsg}</p>
    </Modal>
  </RenderInBody>
);

Error.propTypes = {
  title: PropTypes.string,
  errorMsg: PropTypes.string.isRequired,
  closeCallback: PropTypes.func,
};

Error.defaultProps = {
  title: t('javascript.error_page.error'),
  closeCallback: () => {},
};

export default Error;
