/* eslint react/no-multi-comp:off */

import { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { addLeg } from 'BetBuilder/ducks/composer';
import { makeGetMarket, makeGetMarketComponents } from 'BetBuilder/selectors/market';
import { makeGetLeg } from 'BetBuilder/selectors/composer';

import Market from './market';

/* ------- Redux Container Component ------- */

const redirectToMarkets = (props) => () => props.history.push('/');

class MarketContainer extends PureComponent {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { legIndex, market, addLeg: addLegProp } = this.props;
    // If we're not accessing an existent leg we will
    // create a new one for this market
    if (legIndex === '-1' && market.active) {
      addLegProp();
    }
  }

  render() {
    const {
      market, components, leg, legIndex, addLeg: addLegProp, history,
    } = this.props;
    return (
      <Market
        {...market}
        components={components}
        leg={leg}
        legIndex={legIndex}
        addLeg={addLegProp}
        redirectToMarkets={redirectToMarkets(this.props)}
        history={history}
      />
    );
  }
}

MarketContainer.propTypes = {
  market: PropTypes.instanceOf(Object).isRequired,
  components: PropTypes.instanceOf(Array).isRequired,
  legIndex: PropTypes.number.isRequired,
  addLeg: PropTypes.func.isRequired,
  leg: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = () => {
  const getMarket = makeGetMarket();
  const getLeg = makeGetLeg();
  const getMarketComponents = makeGetMarketComponents();

  return (state, ownProps) => {
    // This could be undefined if we're not accessing
    // a specific already existent leg
    const { legIndex } = ownProps.match.params;

    return {
      market: getMarket(state, ownProps),
      components: getMarketComponents(state, ownProps),
      leg: getLeg(state, ownProps),
      legIndex,
    };
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  addLeg() {
    dispatch(addLeg(ownProps.match.params.id, redirectToMarkets(ownProps)));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MarketContainer);
