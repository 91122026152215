import PropTypes from 'prop-types';

const TeamLink = ({
  activeGroup, groupId, changeActiveGroup, teamName,
}) => (
  <span
    className={`bvs-link bb-playerlist-team ${activeGroup === groupId && 'bb-playerlist-active'}`}
    onClick={() => changeActiveGroup(groupId)}
  >
    {teamName}
  </span>
);

TeamLink.propTypes = {
  groupId: PropTypes.number.isRequired,
  teamName: PropTypes.string.isRequired,
  activeGroup: PropTypes.number.isRequired,
  changeActiveGroup: PropTypes.func.isRequired,
};

export default TeamLink;
