import { createSelector } from 'reselect';

const getItems = (state) => state.betBuilder.marketsTree;
const getCurrentItem = (state, props) => state.betBuilder.marketsTree
  .find((item) => item.id === parseInt(props.match.params.id, 10));

export const getRootItems = createSelector(
  getItems,
  (items) => items.filter((item) => item.root),
);

export const makeGetItemChildren = () => createSelector(
  getCurrentItem,
  getItems,
  (item, items) => items.filter((i) => item.children.includes(i.id)),
);
