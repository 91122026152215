import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getOutcomeAdded } from 'BetBuilder/selectors/outcome';
import createOutcome from 'BetBuilder/services/create_outcome';
import { removeErrorMessage, removeInfoMessage } from 'BetBuilder/ducks/price';
import Price from './price';

const PriceContainer = (props) => <Price {...props} />;

PriceContainer.propTypes = {
  value: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  createOutcome: PropTypes.func.isRequired,
  redirectToMarkets: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  value: state.betBuilderPrice.value,
  loading: state.betBuilderPrice.loading,
  error: state.betBuilderPrice.error,
  info: state.betBuilderPrice.info,
  mpbPrice: state.betBuilderPrice.mpbPrice,
  outcomeAdded: getOutcomeAdded(state),
});

const mapDispatchToProps = (dispatch) => ({
  createOutcome,
  removeErrorMessage() {
    dispatch(removeErrorMessage());
  },
  removeInfoMessage() {
    dispatch(removeInfoMessage());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PriceContainer);
