import _ from 'underscore';

import legsValid from '../services/legs_valid';
import backendOutcomeBuilder from '../services/backend_outcome_builder';
import apiFetchPrice from '../api/price/api';

export const FETCH_PRICE_INIT = 'betBuilder/FETCH_PRICE_INIT';
export const FETCH_PRICE_SUCCESS = 'betBuilder/FETCH_PRICE_SUCCESS';
export const FETCH_PRICE_ERROR = 'betBuilder/FETCH_PRICE_ERROR';
export const REMOVE_PRICE = 'betBuilder/REMOVE_PRICE';
export const REMOVE_ERROR_MESSAGE = 'betBuilder/REMOVE_ERROR_MESSAGE';
export const REMOVE_INFO_MESSAGE = 'betBuilder/REMOVE_INFO_MESSAGE';
export const REMOVE_LEG_INDEX = 'betBuilder/REMOVE_LEG_INDEX';
export const SET_MPB_PRICE = 'betBuilder/SET_MPB_PRICE';
export const REMOVE_MPB_PRICE = 'betBuilder/REMOVE_MPB_PRICE';

export const fetchPriceInit = () => ({
  type: FETCH_PRICE_INIT,
});

export const fetchPriceSuccess = (legIndex) => (priceResponse) => ({
  type: FETCH_PRICE_SUCCESS,
  legIndex,
  priceResponse,
});

export const fetchPriceError = (error) => ({
  type: FETCH_PRICE_ERROR,
  error,
});

export const removeLegIndex = () => ({
  type: REMOVE_LEG_INDEX,
});

export const removeErrorMessage = () => ({
  type: REMOVE_ERROR_MESSAGE,
});

export const removeInfoMessage = () => ({
  type: REMOVE_INFO_MESSAGE,
});

export const removePrice = () => ({
  type: REMOVE_PRICE,
});

export const setMpbPrice = () => ({
  type: SET_MPB_PRICE,
});

export const removeMpbPrice = () => ({
  type: REMOVE_MPB_PRICE,
});

/*
  TODO:
    - Refactor
    - See if we can change or improve the then / catch behavior
    - Maybe store the error as well in the state to be able to display it
*/

const throttledFetch = (throttlePeriod) => (
  _.throttle((dispatch, composerState, legIndex, eventId) => {
    const dispatchSuccess = _.compose(dispatch, fetchPriceSuccess(legIndex));
    const dispatchError = _.compose(dispatch, fetchPriceError);
    const dispatchRemoveLegIndex = _.compose(dispatch, removeLegIndex);

    if (legsValid(composerState)) {
      apiFetchPrice(backendOutcomeBuilder(composerState, eventId))
        .then(dispatchSuccess)
        .catch(dispatchError);

      setTimeout(dispatchRemoveLegIndex, 2000);
    } else {
      dispatchError();
    }
  }, throttlePeriod)
);

let memoizedThrottledFetch;
// We're adding legIndex here to have knowledge regarding which
// was the leg that triggered the price change event
export const fetchPrice = (legIndex) => (dispatch, getState) => {
  const composerState = getState().betBuilderComposer;
  const betBuilderState = getState().betBuilder;
  const eventId = Object.keys(betBuilderState.event)[0];
  // We need to memoize the throttled version returned by throttledFetch
  // so we ensure it is just called every n milliseconds
  memoizedThrottledFetch = memoizedThrottledFetch
    || throttledFetch(betBuilderState.config.price_low_pass_filter_ms);

  dispatch(fetchPriceInit());
  memoizedThrottledFetch(dispatch, composerState, legIndex, eventId);
};

const emptyError = {
  legs: [],
};

const emptyInfo = {
  // We are not using legs right now on info messages
  // but they were added by the BE just in case we need them
  // in the future
  infoCode: undefined,
  infoMsg: undefined,
};

const initialState = {
  loading: false,
  value: undefined,
  error: emptyError,
  info: emptyInfo,
  triggeredByLeg: undefined,
  mpbPrice: undefined,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_PRICE_INIT:
      return {
        ...state,
        loading: true,
        value: undefined,
        error: emptyError,
      };

    case FETCH_PRICE_SUCCESS: {
      const { price, info } = action.priceResponse;

      return {
        loading: false,
        value: price,
        error: emptyError,
        info: info || emptyInfo,
        triggeredByLeg: action.legIndex,
      };
    }

    case FETCH_PRICE_ERROR: {
      const error = action.error || emptyError;

      return {
        ...state,
        loading: false,
        value: undefined,
        // There are errors from the backend returning the legs
        // field as null, for consistency in the code
        //  we are assigning it to [] in case this happens
        error: {
          ...error,
          legs: error.legs || [],
        },
      };
    }

    case REMOVE_ERROR_MESSAGE:
      return {
        ...state,
        error: {
          ...state.error,
          errorMsg: undefined,
          errorCode: undefined,
        },
      };

    case REMOVE_INFO_MESSAGE:
      return {
        ...state,
        info: emptyInfo,
      };

    case REMOVE_LEG_INDEX:
      return {
        ...state,
        triggeredByLeg: undefined,
      };

    case SET_MPB_PRICE:
      return {
        ...state,
        mpbPrice: true,
      };

    case REMOVE_MPB_PRICE:
      return {
        ...state,
        mpbPrice: false,
      };

    case REMOVE_PRICE:
      return initialState;

    default:
      return state;
  }
};
