import { createSelector } from 'reselect';

const getMarkets = (state) => state.betBuilder.markets;
const getMarketId = (state, props) => props.marketId;
const getUrlMarketId = (state, props) => props.match.params.id;
const getComponents = (state) => state.betBuilder.components;
const getMarketsTree = (state) => state.betBuilder.marketsTree;

export const makeGetMarket = () => createSelector(
  [
    getMarkets,
    getUrlMarketId,
  ],
  (markets, marketId) => markets[marketId],
);

export const makeGetMarketComponents = () => createSelector(
  [
    makeGetMarket(),
    getComponents,
  ],
  (market, components) => market.components.map((cId) => components[cId]),
);

export const makeGetIcon = () => createSelector(
  [
    getMarketId,
    getMarketsTree,
  ],
  (marketId, marketsTree) => (
    (marketsTree.find((market) => market.icon && market.id === marketId)
      || marketsTree.find((market) => market.children.includes(marketId))
      || { icon: '' }
    ).icon
  ),
);
