/* eslint consistent-return: off */
import _ from 'underscore';
import { loggedIn } from 'bv-helpers/session';
import { setFragment } from 'bv-helpers/location';
import apiCreateOutcome from 'BetBuilder/api/outcome/api';
import legsValid from 'BetBuilder/services/legs_valid';
import backendOutcomeBuilder from 'BetBuilder/services/backend_outcome_builder';
import {
  createOutcomeInit,
  createOutcomeFinish,
  createOutcomeError,
  outcomeAdded,
  removeBetAddedRibbon,
} from 'BetBuilder/ducks/outcome';

// Sportsbook connection ------
import { addOutcomes } from 'sportsbook-outcomes-duck';
import { makeGetOutcome } from 'sportsbook-outcomes-selector';
import { addToBetslip, removeFromBetslip } from 'sportsbook-betslip-thunks';
import { makeOutcomeInBetslip } from 'sportsbook-betslip-selector';
// ---------------------------

const { dispatch, getState } = window.reduxState.store;

const rejectIfError = (outcomeResponse) => (outcomeResponse.errorCode
  && Promise.reject(outcomeResponse))
  || Promise.resolve(outcomeResponse);

const waitIfNeeded = (waitingPeriod) => (outcome) => (
  outcome.new && new Promise((resolve) => {
    setTimeout(() => resolve(outcome), waitingPeriod);
  })) || Promise.resolve(outcome);

const handleBetslip = (clickSource) => (outcome) => {
  const outcomeInState = makeGetOutcome()(getState(), { id: outcome.id });
  const outcomeInBetslip = makeOutcomeInBetslip()(getState(), { id: outcome.id });

  if (outcomeInBetslip) {
    // Remove the outcome from the betslip
    dispatch(removeFromBetslip(outcome.id));
    // Remove the bet added ribbon
    dispatch(removeBetAddedRibbon());
  } else {
    // We will just add the created outcome to the state if it was not
    // added previously. we could click multiple times on the create outcome
    // button but we just need to increase the outcome refCount attribute once
    if (!outcomeInState) {
      dispatch(addOutcomes([outcome]));
    }
    // Add the outcome to the betslip
    dispatch(addToBetslip(outcome.id, { clickSource }));
    // Dispatch that the outcome was added
    dispatch(outcomeAdded());
  }
};

export default () => {
  const {
    betBuilderComposer,
    betBuilderPrice,
    betBuilder,
  } = getState();

  // Our precondition in order to create the outcome is that all its
  // legs are valid and we got a price from the backend which should be stored
  // into our redux state
  if (!(legsValid(betBuilderComposer) && betBuilderPrice.value)) {
    return;
  }
  // --------------------------------------------------------------------

  // If the user is not logged in redirect him to login screen
  if (!loggedIn()) return setFragment('login');
  // ---------------------------------------------------------------

  dispatch(createOutcomeInit());
  apiCreateOutcome(backendOutcomeBuilder(betBuilderComposer, Object.keys(betBuilder.event)[0]))
    .then(rejectIfError)
    .then(waitIfNeeded(betBuilder.config.outcome_creation_ms))
    .then(handleBetslip(betBuilder.urlConfigs.clickSource))
    .then(_.compose(dispatch, createOutcomeFinish))
    .catch(_.compose(dispatch, createOutcomeError));
};
