import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'bv-components';
import itemLinkPath from 'BetBuilder/services/navigation_item_link_path';
import Icon from './icon';

const itemClass = (props) => classnames('bb-market-list__type', {
  'bb-market-list__secondary': !props.icon,
});

const Item = (props) => {
  const { icon, title } = props;

  return (
    <Link data-react="true" to={itemLinkPath(props)}>
      <span className={itemClass(props)}>
        {icon && <Icon icon={icon} />}
        <i className="bb-market-list__title">
          {title}
        </i>
      </span>
    </Link>
  );
};

Item.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Item;
