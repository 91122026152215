import { useRef } from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'bv-components';

const HorizontalList = ({ selections, title, replaceSelections }) => {
  const scrollableRef = useRef(null);

  return (
    <div className="bb-period-selector bb-market-component bb-horizontal-list">
      <span className="bb-title-component">{title}</span>
      <Carousel scrollStep={284} ref={scrollableRef}>
        <div className="bb-period-selector__periods">
          {selections.map((selection) => (
            <div
              className={`bvs-link bb-period-selector__period ${selection.selected ? 'active' : ''}`}
              onClick={() => !selection.selected && replaceSelections([selection])}
            >
              {selection.title}
            </div>
          ))}
        </div>
      </Carousel>
    </div>
  );
};

HorizontalList.propTypes = {
  title: PropTypes.string.isRequired,
  selections: PropTypes.instanceOf(Array).isRequired,
  replaceSelections: PropTypes.func.isRequired,
};

export default HorizontalList;
