import PropTypes from 'prop-types';
import { Modal, RenderInBody, SmallSpinner } from 'bv-components';
import { t } from 'bv-i18n';

const CreatingOutcome = ({ title, msg }) => (
  <RenderInBody>
    <Modal
      className="bb-creating-outcome"
      title={title}
      info
    >
      <p>{msg}</p>
      <SmallSpinner />
    </Modal>
  </RenderInBody>
);

CreatingOutcome.propTypes = {
  title: PropTypes.string,
  msg: PropTypes.string,
};

CreatingOutcome.defaultProps = {
  title: t('javascript.bet_builder.app.creating_outcome'),
  msg: '',
};

export default CreatingOutcome;
