import { getJSON } from 'bv-fetch';

import urls from './urls';
import normalizeResponse from './normalize';

// If this get more complex extract and refactor
const present = (response) => response.entities;

export default (eventData) => (
  getJSON(urls.eventUrl(eventData))
    .then(normalizeResponse)
    .then(present)
);
