import { useParams } from 'react-router-dom';
import { Link } from 'bv-components';
import classnames from 'classnames';
import WithPrice from 'BetBuilder/hocs/with_price';
import { t } from 'bv-i18n';

const moreMarketsClassName = (loading) => classnames('bb-more-markets', {
  'is-disabled': loading,
});

export default WithPrice(({ price: { loading } }) => (
  <Link
    to={`/bet-builder/${useParams().eventId}`}
    className={moreMarketsClassName(loading)}
    data-react
    onClick={(e) => loading && e.preventDefault()}
  >
    <button type="button" className="is-plus-icon with-animation" />
    <span>{t('javascript.bet_builder.app.more_markets')}</span>
  </Link>
));
