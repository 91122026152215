/* eslint max-len: off */

import { createSelector } from 'reselect';

const getComponentSelectionIds = (state, props) => props.selections;
const getSelections = (state) => state.betBuilder.selections;
const getBetBuilderComposerSelections = (state, props) => {
  const legIndex = props.legIndex === '-1' ? state.betBuilderComposer.legs.length - 1 : props.legIndex;

  return (state.betBuilderComposer.legs[legIndex]
    && state.betBuilderComposer.legs[legIndex].components[props.id])
    || [];
};

export const makeGetComponentSelections = () => createSelector(
  [
    getComponentSelectionIds,
    getSelections,
  ],
  (componentSelectionIds, selections) => componentSelectionIds.map((selId) => selections[selId]),
);

export const makeGetComponentProcessedSelections = () => createSelector(
  [
    makeGetComponentSelections(),
    getBetBuilderComposerSelections,
  ],
  (componentSelections, betBuilderComposerSelections) => componentSelections
    .map((selection) => ({
      ...selection,
      selected: betBuilderComposerSelections
        .some((betBuilderSelection) => betBuilderSelection.id === selection.id),
    })),
);
