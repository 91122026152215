import PropTypes from 'prop-types';
import { withScrollToTop } from 'bv-hocs';
import { t } from 'bv-i18n';

import ErrorModal from 'BetBuilder/components/modals/error';
import BbComponentContainer from './bb_component/bb_component_container';
import AddSelection from './add_selection';

/* ------- Presentational Component ------- */

const Market = ({
  components, id, legIndex, active, forbidAddMore, leg, addLeg, redirectToMarkets,
}) => {
  const mandatoryBbComponents = components
    .filter((component) => component.mandatory);
  const optionalBbComponents = components
    .filter((component) => !component.mandatory);
  const componentContainer = (component) => (
    <BbComponentContainer
      {...component}
      marketId={id}
      legIndex={legIndex}
      key={component}
    />
  );

  return (
    active
      ? (
        <div className="bvs-card bb-markets">
          <AddSelection
            marketId={id}
            forbidAddMore={forbidAddMore}
            leg={leg}
            addLeg={addLeg}
          />

          {mandatoryBbComponents.map(componentContainer)}

          {optionalBbComponents.map((component) => (
            <>
              <hr />
              {componentContainer(component)}
            </>
          ))}
        </div>
      )
      : (
        <ErrorModal
          title={t('javascript.bet_builder.app.errors.market.title')}
          errorMsg={t('javascript.bet_builder.app.errors.market.body')}
          closeCallback={redirectToMarkets}
        />
      )
  );
};

Market.propTypes = {
  id: PropTypes.number.isRequired,
  legIndex: PropTypes.number.isRequired,
  components: PropTypes.instanceOf(Array).isRequired,
  leg: PropTypes.instanceOf(Object).isRequired,
  addLeg: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  redirectToMarkets: PropTypes.func.isRequired,
  forbidAddMore: PropTypes.bool.isRequired,
};

export default withScrollToTop()(Market);
