import { loadState, flushState } from './local_storage';

export default ({ eventId, addFullOutcome, dispatch }) => {
  const { timestamp, ...legsObject } = loadState(eventId);

  if (legsObject) {
    dispatch(addFullOutcome(Object.values(legsObject)));
  }

  // We will always delete all betBuilder local storage entries after
  // restoring an outcome, even if it was not found for this event
  flushState();
};
