import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { SliderSelector } from 'bv-components';
import _, { partial, pick } from 'underscore';

class Slider extends PureComponent {
  constructor(props) {
    super(props);

    this.selectionChange = this.selectionChange.bind(this);
  }

  selectionChange(event) {
    const { selections, selectionChange } = this.props;
    const selectedValue = parseInt(event.target.value, 10);

    selectionChange(
      selections.find((selection) => (
        selection.id === selectedValue
      )),
    );
  }

  render() {
    const { selections, selectedId } = this.props;
    return (
      <SliderSelector
        selections={selections.map(partial(pick, _, 'id', 'title'))}
        selectedId={selectedId}
        onChange={this.selectionChange}
        triggerWhileScrolling={false}
        big
      />
    );
  }
}

Slider.propTypes = {
  selections: PropTypes.instanceOf(Array).isRequired,
  selectionChange: PropTypes.func.isRequired,
  selectedId: PropTypes.number.isRequired,
};

export default Slider;
