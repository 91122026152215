import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import nullifySelection from 'BetBuilder/services/nullify_selection';
import ConditionedScroller from './conditioned_scroller';
import Stepper from './stepper';

/* --------------------------------------------------------------------------- */

const selected = (selection) => selection.selected;
const belongsToScroller = (selection) => selection && [0, 1].includes(selection.groupId);
const belongsToStepper = (selection) => selection && selection.groupId === 2;

const TAB_OU = 'TAB_OU';
const TAB_EXACT = 'TAB_EXACT';

const tabInfos = [
  {
    tab: TAB_OU,
    translationKey: 'scroller',
  },
  {
    tab: TAB_EXACT,
    translationKey: 'stepper',
  },
];

class ConditionedScrollerAndStepper extends PureComponent {
  constructor(props) {
    super(props);

    this.changeTab = this.changeTab.bind(this);
  }

  setInitialConditionedScrollerSelections() {
    const { replaceSelections } = this.props;
    replaceSelections([
      this.switchSelections()[0],
      nullifySelection(this.scrollerSelections()[0]),
    ]);
  }

  setInitialStepperSelections() {
    const { replaceSelections } = this.props;
    replaceSelections([
      nullifySelection(this.stepperSelections()[0]),
    ]);
  }

  conditionedScrollerSelections() {
    const { selections } = this.props;
    return selections
      .filter(belongsToScroller);
  }

  switchSelections() {
    return this.conditionedScrollerSelections()
      .filter((selection) => selection.groupId === 0);
  }

  scrollerSelections() {
    return this.conditionedScrollerSelections()
      .filter((selection) => selection.groupId === 1);
  }

  stepperSelections() {
    const { selections } = this.props;
    return selections
      .filter(belongsToStepper);
  }

  activeTab() {
    const { selections } = this.props;
    const firstSelected = selections.find(selected);

    return belongsToScroller(firstSelected) ? TAB_OU : TAB_EXACT;
  }

  changeTab(tab) {
    const { defaultifyLegSelections } = this.props;
    return () => {
      defaultifyLegSelections();

      return tab === TAB_OU
        ? this.setInitialConditionedScrollerSelections()
        : this.setInitialStepperSelections();
    };
  }

  render() {
    const { specificInfo, title, replaceSelections } = this.props;

    const stepperSelectionChange = (selection) => {
      replaceSelections([
        selection,
      ]);
    };

    const activeTab = this.activeTab();

    return (
      <>
        <div className="bb-team-list__item bb-market-component">
          <span className="bb-title-component">
            {title}
          </span>
        </div>

        <div className="bb-specific-info-wrapper">
          {
            tabInfos.map((tabInfo) => (
              <div
                onClick={this.changeTab(tabInfo.tab)}
                className={`bvs-link bb-specific-info-button ${activeTab === tabInfo.tab ? 'active' : ''}`}
              >
                {specificInfo.translations[tabInfo.translationKey]}
              </div>
            ))
          }

          {
            activeTab === TAB_OU && (
            <ConditionedScroller
              {...this.props}
              selections={this.conditionedScrollerSelections()}
              replaceSelections={replaceSelections}
            />
            )
          }
          {
            activeTab === TAB_EXACT && (
            <Stepper
              {...this.props}
              selections={this.stepperSelections()}
              selectionChange={stepperSelectionChange}
            />
            )
          }
        </div>
      </>
    );
  }
}

ConditionedScrollerAndStepper.propTypes = {
  title: PropTypes.string.isRequired,
  selections: PropTypes.instanceOf(Array).isRequired,
  specificInfo: PropTypes.instanceOf(Object).isRequired,
  replaceSelections: PropTypes.func.isRequired,
  defaultifyLegSelections: PropTypes.func.isRequired,
};

export default ConditionedScrollerAndStepper;
