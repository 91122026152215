import PropTypes from 'prop-types';
import ErrorModal from 'BetBuilder/components/modals/error';
import InfoModal from 'BetBuilder/components/modals/info';
import classnames from 'classnames';
import { Spinner } from 'bv-components';
import { t, exist } from 'bv-i18n';
import { FormattedPrice } from 'SportsbookCommon';

export const PriceButton = ({
  value,
  createOutcome,
  loading,
  outcomeAdded,
  mpbPrice,
}) => (
  <div
    className={classnames(
      'bb-results-price-container',
      {
        'bb-is-mpb': mpbPrice,
        selected: outcomeAdded,
      },
    )}
  >
    <button type="button" className="bb-results-price-container__price" onClick={createOutcome}>
      {loading ? (
        <Spinner wrapped={false} />
      ) : (
        <FormattedPrice price={value} />
      )}
    </button>
  </div>
);

PriceButton.propTypes = {
  value: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  outcomeAdded: PropTypes.bool.isRequired,
  createOutcome: PropTypes.func.isRequired,
  mpbPrice: PropTypes.bool.isRequired,
};

const pricingMessageKey = (messageType) => (code, entity) => {
  const key = `javascript.bet_builder.app.${messageType}.pricing.${code}.${entity}`;

  if (exist(key)) {
    return key;
  }

  return `javascript.bet_builder.app.${messageType}.pricing.default.${entity}`;
};

const errorMessageKey = pricingMessageKey('errors');
const infoMessageKey = pricingMessageKey('info');

const Price = ({
  outcomeAdded,
  value,
  createOutcome,
  loading,
  mpbPrice,
  error,
  info,
  removeErrorMessage,
  removeInfoMessage,
}) => ([
  <PriceButton
    outcomeAdded={outcomeAdded}
    value={value}
    createOutcome={createOutcome}
    loading={loading}
    mpbPrice={mpbPrice}
  />,
  error.errorCode && (
    <ErrorModal
      title={t(errorMessageKey(error.errorCode, 'title'))}
      errorMsg={t(errorMessageKey(error.errorCode, 'body'))}
      closeCallback={removeErrorMessage}
    />
  ),
  info.infoCode && (
    <InfoModal
      title={t(infoMessageKey(info.infoCode, 'title'))}
      infoMsg={t(infoMessageKey(info.infoCode, 'body'))}
      closeCallback={removeInfoMessage}
    />
  ),
]);

Price.propTypes = {
  value: PropTypes.string,
  loading: PropTypes.bool,
  info: PropTypes.instanceOf(Object),
  error: PropTypes.instanceOf(Object),
  outcomeAdded: PropTypes.bool,
  removeErrorMessage: PropTypes.func,
  removeInfoMessage: PropTypes.func,
  createOutcome: PropTypes.func.isRequired,
  mpbPrice: PropTypes.bool.isRequired,
};

Price.defaultProps = {
  value: '-',
  loading: false,
  outcomeAdded: false,
  error: {},
  info: {},
  removeErrorMessage: () => {},
  removeInfoMessage: () => {},
};

export default Price;
