const buildStorage = ({ storagePrefix, storage = localStorage } = {}) => {
  const storageKey = (itemId) => `${storagePrefix}-${itemId}`;

  const loadState = (itemId) => {
    try {
      const serializedState = storage.getItem(storageKey(itemId));

      if (serializedState === null) {
        return {};
      }

      return JSON.parse(serializedState);
    } catch (err) {
      return {};
    }
  };

  const saveState = (itemId, state) => {
    try {
      const serializedState = JSON.stringify(state);

      storage.setItem(storageKey(itemId), serializedState);
    } catch (err) {
      // Ignore write errors
    }
  };

  const flushState = () => {
    try {
      // Array from 0 to localStorage.length (indexes)
      const localStorageLengthArray = Array.from({ length: storage.length }, (v, k) => k);
      // Collect all localStorage keys that matches our prefix
      const keysToDelete = localStorageLengthArray.reduce((acc, index) => {
        const keyForIndex = storage.key(index);
        const key = keyForIndex.substring(0, storagePrefix.length) === storagePrefix && keyForIndex;

        if (!key) {
          return acc;
        }

        return [
          ...acc,
          key,
        ];
      }, []);

      // Delete all keys
      keysToDelete.forEach((key) => storage.removeItem(key));
    } catch (err) {
      // Ignore local storage errors
    }
  };

  return {
    loadState,
    saveState,
    flushState,
  };
};

export default buildStorage;
