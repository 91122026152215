import getEvent from '../api/event/api';
import { setTemplates, addFullOutcome } from './composer';
import restoreOutcome from '../services/restore_outcome';

export const FETCH_EVENT_INIT = 'betBuilder/FETCH_EVENT_INIT';
export const FETCH_EVENT_SUCCESS = 'betBuilder/FETCH_EVENT_SUCCESS';
export const REMOVE_EVENT = 'betBuilder/REMOVE_EVENT';
export const SET_URL_CONFIGS = 'betBuilder/SET_URL_CONFIGS';

export const fetchEventInit = () => ({
  type: FETCH_EVENT_INIT,
});

export const fetchEventSuccess = (eventData) => ({
  type: FETCH_EVENT_SUCCESS,
  eventData,
});

export const removeEvent = () => ({
  type: REMOVE_EVENT,
});

export const setUrlConfigsSuccess = (urlParams) => ({
  type: SET_URL_CONFIGS,
  urlParams,
});

export const fetchEvent = ({ eventId, outcomeId }) => (dispatch) => {
  dispatch(fetchEventInit());

  return getEvent({ eventId, outcomeId })
    .then((data) => {
      /*
        TODO:

          - having the event indexed by id in the state doesnt seem to help
          at all as it is always a single instance. It is adding unneeded
          complexity to the code

          - The event response sideload other things that are needed for the
          app, this is being done like this to avoid making multiple calls
          The sideloaded things are:

          - templates
          - marketsTree
          - config
          - outcome (if present)

          On the following code we destructure that information from the
          event response and dispatch each proper action with its piece of data
      */

      const eventResponse = Object.values(data.event)[0];
      const {
        templates,
        marketsTree,
        tutorial,
        displayTutorial,
        outcome,
        config,
        ...event
      } = eventResponse;
      const eventData = {
        ...data,
        tutorial,
        displayTutorial,
        marketsTree,
        config,
        event: {
          [eventId]: event,
        },
      };

      dispatch(fetchEventSuccess(eventData));

      /* Additional Data returned by event endpoint */
      dispatch(setTemplates(templates));

      if (outcome) {
        dispatch(addFullOutcome(outcome.legs));
      } else {
        // restore outcome if there's any for the event stored
        // we are adding this to the else block after checking if an
        // outcome was provided because if that's the case, we don't
        // want to restore whatever has been saved before
        restoreOutcome({
          eventId,
          addFullOutcome,
          dispatch,
        });
      }
      /* ------------------------------------ */
    });
};

export const setUrlConfigs = (urlParams) => (dispatch) => {
  dispatch(setUrlConfigsSuccess(urlParams));
};

const initialState = {
  loading: true,
  event: {},
  tutorial: [],
  displayTutorial: true,
  markets: {},
  components: {},
  selections: {},
  urlConfigs: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_EVENT_SUCCESS:
      return {
        loading: false,
        urlConfigs: state.urlConfigs,
        ...action.eventData,
      };

    case FETCH_EVENT_INIT:
      return initialState;

    case REMOVE_EVENT:
      return initialState;

    case SET_URL_CONFIGS:
      return {
        ...state,
        urlConfigs: {
          clickSource: action.urlParams.get('clickSource'),
        },
      };
    default:
      return state;
  }
};
