/* eslint no-case-declarations: off, max-len: off, prefer-spread: off, consistent-return: off */

import { flatten, last } from 'underscore';
import replaceLegComponents from 'BetBuilder/services/replace_leg_components';
import { buildLeg, buildDefaultLeg } from '../services/build_leg';
import translationBuilder from '../services/translation_builder';
import replaceRelatedSelections from '../services/replace_related_selections';
import filterOutcomeLegs from '../services/filter_outcome_legs';
import { fetchPrice } from './price';

export const SET_LIMIT_REACHED = 'betBuilder/SET_LIMIT_REACHED';
export const REMOVE_LIMIT_REACHED = 'betBuilder/REMOVE_LIMIT_REACHED';
export const ADD_LEG_SUCCESS = 'betBuilder/ADD_LEG_SUCCESS';
export const DEFAULTIFY_LEG_SELECTIONS_SUCCESS = 'betBuilder/DEFAULTIFY_LEG_SELECTIONS_SUCCESS';
export const REMOVE_LEG_SUCCESS = 'betBuilder/REMOVE_LEG_SUCCESS';
export const EMPTY_LEGS_SUCCESS = 'betBuilder/EMPTY_LEGS_SUCCESS';
export const ADD_SELECTIONS_SUCCESS = 'betBuilder/ADD_SELECTIONS_SUCCESS';
export const SET_TEMPLATES = 'betBuilder/SET_TEMPLATES';
export const REMOVE_COMPOSER = 'betBuilder/REMOVE_COMPOSER';
export const SET_LOCK = 'betBuilder/SET_LOCK';
export const RELEASE_LOCK = 'betBuilder/RELEASE_LOCK';

export const setLimitReached = () => ({
  type: SET_LIMIT_REACHED,
});

export const removeLimitReached = () => ({
  type: REMOVE_LIMIT_REACHED,
});

export const addLegSuccess = (marketId, leg) => ({
  type: ADD_LEG_SUCCESS,
  marketId,
  leg,
});

export const removeComposer = () => ({
  type: REMOVE_COMPOSER,
});

export const addLeg = (marketId, redirectToMarkets) => (dispatch, getState) => {
  const legIndex = getState().betBuilderComposer.legs.length;
  const betBuilderState = getState().betBuilder;

  const performLimitReached = () => {
    dispatch(setLimitReached());
    redirectToMarkets();
  };

  const performAddLeg = () => {
    dispatch(addLegSuccess(marketId, buildLeg(marketId, betBuilderState)));
    dispatch(fetchPrice(legIndex));
  };

  // TODO the limit will come as a config parameter from the BE
  return legIndex >= 10 ? performLimitReached() : performAddLeg();
};

export const removeLegSuccess = (legIndex) => ({
  type: REMOVE_LEG_SUCCESS,
  legIndex,
});

export const removeLeg = (legIndex) => (dispatch) => {
  dispatch(removeLegSuccess(legIndex));
  dispatch(fetchPrice());
};

export const emptyLegsSuccess = () => ({
  type: EMPTY_LEGS_SUCCESS,
});

export const emptyLegs = () => (dispatch) => {
  dispatch(emptyLegsSuccess());
  dispatch(fetchPrice());
};

export const addSelectionsSuccess = (legIndex, component, selections, stateSelections) => {
  const componentSelections = selections.map((selection) => ({
    ...selection,
    componentId: component.id,
  }));

  return {
    type: ADD_SELECTIONS_SUCCESS,
    legIndex,
    componentId: component.id,
    selections: componentSelections,
    stateSelections,
  };
};

export const addSelections = (legIndex, component, selections) => (dispatch, getState) => {
  const processedLegIndex = legIndex === '-1'
    ? getState().betBuilderComposer.legs.length - 1
    : parseInt(legIndex, 10);
  const stateSelections = getState().betBuilder.selections;

  dispatch(addSelectionsSuccess(processedLegIndex, component, selections, stateSelections));
  dispatch(fetchPrice(processedLegIndex));
};

export const defaultifyLegSelectionsSuccess = (legIndex, selections) => ({
  type: DEFAULTIFY_LEG_SELECTIONS_SUCCESS,
  legIndex,
  selections,
});

export const defaultifyLegSelections = (legIndex) => (dispatch, getState) => {
  const processedLegIndex = legIndex === '-1'
    ? getState().betBuilderComposer.legs.length - 1
    : parseInt(legIndex, 10);

  const composerState = getState().betBuilderComposer;
  const betBuilderState = getState().betBuilder;

  const leg = composerState.legs[processedLegIndex] || last(composerState.legs);
  const defaultSelections = buildDefaultLeg(Object.keys(leg.components), betBuilderState);

  dispatch(defaultifyLegSelectionsSuccess(processedLegIndex, defaultSelections));
};

export const setLock = () => ({
  type: SET_LOCK,
});

export const releaseLock = () => ({
  type: RELEASE_LOCK,
});

export const addFullOutcome = (outcomeData) => (dispatch, getState) => {
  // We use this recursive function to apply the cascade effect when
  // adding the outcomes to the composer
  const delayedAdd = (legs) => {
    const [leg, ...rest] = legs;

    if (!leg) {
      dispatch(fetchPrice());
      dispatch(releaseLock());
      return;
    }

    dispatch(addLegSuccess(leg.marketId, leg.components));

    setTimeout(() => {
      delayedAdd(rest);
    }, 400);
  };

  dispatch(setLock());
  delayedAdd(filterOutcomeLegs(outcomeData, getState));
};

export const setTemplates = (templates) => ({
  type: SET_TEMPLATES,
  templates,
});

const initialState = {
  legs: [],
  templates: {},
  limitReached: false,
  locked: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ADD_LEG_SUCCESS: {
      return {
        ...state,
        legs: [
          ...state.legs,
          {
            marketId: action.marketId,
            components: action.leg,
            description: translationBuilder(
              state.templates,
              flatten(Object.values(action.leg)),
            ),
          },
        ],
      };
    }

    case REMOVE_LEG_SUCCESS: {
      const removingLeg = state.legs[action.legIndex];

      // If the leg is not on the state we return the same state
      if (!removingLeg) {
        return state;
      }

      return {
        ...state,
        legs: [
          ...state.legs.slice(0, action.legIndex),
          ...state.legs.slice(action.legIndex + 1),
        ],
      };
    }

    case EMPTY_LEGS_SUCCESS:
      return {
        ...initialState,
        templates: state.templates,
      };

    case ADD_SELECTIONS_SUCCESS: {
      const leg = state.legs[action.legIndex];
      // ------ Get all related selections for the selections being added
      const relatedSelections = action.selections.reduce((acc, selection) => ({
        ...acc,
        ...selection.relatedSelections,
      }), {});
      // ------------------------------------------------------------

      if (leg) {
        const resultComponents = {
          // ------ Replace the current set of selections of the leg with the
          // ------ related ones of the legs being added
          ...replaceRelatedSelections(action.stateSelections, leg.components, relatedSelections),
          // --------------------------------------------------------
          [action.componentId]: action.selections,
        };

        return replaceLegComponents(
          state,
          action.legIndex,
          resultComponents,
        );
      }

      return state;
    }

    case DEFAULTIFY_LEG_SELECTIONS_SUCCESS: {
      const leg = state.legs[action.legIndex] || last(state.legs);

      if (leg) {
        return replaceLegComponents(
          state,
          action.legIndex,
          action.selections,
        );
      }

      return state;
    }

    case SET_TEMPLATES:
      return {
        ...state,
        templates: action.templates,
      };

    case SET_LIMIT_REACHED:
      return {
        ...state,
        limitReached: true,
      };

    case REMOVE_LIMIT_REACHED:
      return {
        ...state,
        limitReached: false,
      };

    case SET_LOCK:
      return {
        ...state,
        locked: true,
      };

    case RELEASE_LOCK:
      return {
        ...state,
        locked: false,
      };

    case REMOVE_COMPOSER:
      return initialState;

    default:
      return state;
  }
};
