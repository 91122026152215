import WithPrice from 'BetBuilder/hocs/with_price';
import { connect } from 'react-redux';
import { legValid } from 'BetBuilder/services/legs_valid';
import { makeGetIcon } from 'BetBuilder/selectors/market';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { t } from 'bv-i18n';

const AddSelection = ({
  addLeg, marketId, forbidAddMore, priceLoadingOrNoValue, leg, icon,
}) => {
  const history = useHistory();
  const { eventId } = useParams();
  const addLegAndRedirect = () => {
    addLeg();
    history.push(`/bet-builder/${eventId}/market/${marketId}/leg/-1`);
  };

  return !forbidAddMore && !priceLoadingOrNoValue && legValid(leg) && (
    <div onClick={addLegAndRedirect} className="bb-ribbon-wrapper flipInX">
      <span className="is-plus-icon" />
      { t('javascript.bet_builder.app.add_another_leg') }
      <span className={`icon bb-market-list__${icon}`} />
    </div>
  );
};

AddSelection.propTypes = {
  addLeg: PropTypes.func.isRequired,
  marketId: PropTypes.number.isRequired,
  forbidAddMore: PropTypes.bool.isRequired,
  priceLoadingOrNoValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]).isRequired,
  leg: PropTypes.instanceOf(Object).isRequired,
  icon: PropTypes.string.isRequired,
};

const mapStateToProps = () => {
  const getIcon = makeGetIcon();

  return (state, ownProps) => ({
    icon: getIcon(state, ownProps),
  });
};

export default WithPrice(connect(mapStateToProps)(AddSelection));
