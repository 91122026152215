/* eslint max-len: off */

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { addSelections, defaultifyLegSelections } from 'BetBuilder/ducks/composer';
import { makeGetComponentProcessedSelections } from 'BetBuilder/selectors/bb_component';
import BbComponent from './bb_component';

/* ------- Helper Methods ------- */

const replaceSelections = (props) => (selections) => {
  // Add the given selections
  props.addSelections(props, selections);
};

/* ------- Redux Container Component ------- */

const BbComponentContainer = (props) => (
  <BbComponent
    {...props}
    replaceSelections={replaceSelections(props)}
  />
);

BbComponentContainer.propTypes = {
  selections: PropTypes.instanceOf(Array).isRequired,
  addSelections: PropTypes.func.isRequired,
};

const mapStateToProps = () => {
  const getComponentProcessedSelections = makeGetComponentProcessedSelections();

  return (state, ownProps) => {
    const selections = getComponentProcessedSelections(state, ownProps);

    return ({
      // This is actually replacing the 'selections' entry
      // into our original props (that were just ids) with this processed ones
      selections,
    });
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  addSelections(component, selections) {
    dispatch(addSelections(ownProps.legIndex, component, selections));
  },
  defaultifyLegSelections() {
    dispatch(defaultifyLegSelections(ownProps.legIndex));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BbComponentContainer);
