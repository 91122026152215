export const CREATE_OUTCOME_INIT = 'betBuilder/CREATE_OUTCOME_INIT';
export const CREATE_OUTCOME_FINISH = 'betBuilder/CREATE_OUTCOME_FINISH';
export const CREATE_OUTCOME_ERROR = 'betBuilder/CREATE_OUTCOME_ERROR';
export const REMOVE_CREATE_OUTCOME_ERROR = 'betBuilder/REMOVE_CREATE_OUTCOME_ERROR';
export const OUTCOME_ADDED = 'betBuilder/OUTCOME_ADDED';
export const REMOVE_OUTCOME_ADDED_RIBBON = 'betBuilder/REMOVE_OUTCOME_ADDED_RIBBON';
export const REMOVE_CLEAR_OR_KEEP = 'betBuilder/REMOVE_CLEAR_OR_KEEP';
export const REMOVE_OUTCOME = 'betBuilder/REMOVE_OUTCOME';

export const createOutcomeInit = () => ({
  type: CREATE_OUTCOME_INIT,
});

export const createOutcomeFinish = () => ({
  type: CREATE_OUTCOME_FINISH,
});

export const createOutcomeError = (outcomeError) => ({
  type: CREATE_OUTCOME_ERROR,
  outcomeError,
});

export const removeCreateOutcomeError = () => ({
  type: REMOVE_CREATE_OUTCOME_ERROR,
});

export const outcomeAdded = () => ({
  type: OUTCOME_ADDED,
});

export const removeBetAddedRibbon = () => ({
  type: REMOVE_OUTCOME_ADDED_RIBBON,
});

export const removeClearOrKeep = () => ({
  type: REMOVE_CLEAR_OR_KEEP,
});

export const removeOutcome = () => ({
  type: REMOVE_OUTCOME,
});

const initialState = {
  creatingOutcome: false,
  creatingOutcomeError: false,
  outcomeAdded: false,
  displayClearOrKeep: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case CREATE_OUTCOME_INIT:
      return {
        ...state,
        creatingOutcome: true,
      };

    case CREATE_OUTCOME_FINISH:
      return {
        ...state,
        creatingOutcome: false,
      };

    case CREATE_OUTCOME_ERROR:
      return {
        ...state,
        creatingOutcome: false,
        creatingOutcomeError: action.outcomeError,
      };

    case REMOVE_CREATE_OUTCOME_ERROR:
      return {
        ...state,
        creatingOutcomeError: false,
      };

    case OUTCOME_ADDED:
      return {
        ...state,
        outcomeAdded: true,
        displayClearOrKeep: true,
      };

    case REMOVE_OUTCOME_ADDED_RIBBON:
      return {
        ...state,
        outcomeAdded: false,
      };

    case REMOVE_CLEAR_OR_KEEP:
      return {
        ...state,
        displayClearOrKeep: false,
      };

    case REMOVE_OUTCOME:
      return initialState;

    default:
      return state;
  }
};
