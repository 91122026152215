import { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { sanitize } from 'dompurify';
import { useHistory, useParams } from 'react-router-dom';

const Leg = ({
  navigateToMarket,
  removeLeg,
  marketId,
  legIndex,
  legsCount,
  description,
  success,
  error,
}) => {
  const history = useHistory();
  const params = useParams();
  const { eventId } = params;
  const paramsLegIndex = parseInt(params.legIndex, 10);

  const handleMarketClick = () => {
    navigateToMarket();
    history.push(`/bet-builder/${eventId}/market/${marketId}/leg/${legIndex}`);
  };

  const handleRemove = () => {
    removeLeg();
    history.push(`/bet-builder/${eventId}`);
  };

  const isActive = useMemo(() => (
    // false -> if there is no parameter for Market Id we are outside a market
    !!marketId && (
      // true -> the index of the leg we are editing is the same as the one in the params
      (legIndex === paramsLegIndex
        // true -> we are editing a new leg (index -1) and this leg index is the last one
        || (paramsLegIndex === -1 && legIndex === legsCount - 1)
      )
    )
  ), [legIndex, marketId, legsCount, paramsLegIndex]);

  return (
    <div>
      <div
        className={classNames({
          'bb-market-added-row': true,
          'bb-market-added-row-success': success,
          'bb-market-added-row-error': error,
        })}
      >
        <span
          className="bb-market-added-row__leg"
          dangerouslySetInnerHTML={{ __html: sanitize(description) }}
          onClick={handleMarketClick}
        />
        {isActive && <button type="button" className="bb-market-added-row__delete" onClick={handleRemove} />}
      </div>
    </div>
  );
};

Leg.propTypes = {
  removeLeg: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  legIndex: PropTypes.number.isRequired,
  legsCount: PropTypes.number.isRequired,
  marketId: PropTypes.number.isRequired,
  navigateToMarket: PropTypes.func.isRequired,
  success: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
};

export default Leg;
