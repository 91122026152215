import { postJSON } from 'bv-fetch';

import urls from './urls';

// If this get more complex extract and refactor
const present = (response) => response;

export default (backendOutcome) => (
  postJSON(urls.outcomeUrl(), backendOutcome)
    .then(present)
);
