import {
  removeBetAddedRibbon,
  removeClearOrKeep,
} from 'BetBuilder/ducks/outcome';
import { emptyLegs } from 'BetBuilder/ducks/composer';

const clear = (dispatch) => {
  dispatch(emptyLegs());
  dispatch(removeBetAddedRibbon());
  dispatch(removeClearOrKeep());
};

const keep = (dispatch) => {
  dispatch(removeBetAddedRibbon());
  dispatch(removeClearOrKeep());
};

export {
  clear,
  keep,
};
