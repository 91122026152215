import {
  any, filter, every, flatten,
} from 'underscore';

const legSelections = (leg) => flatten(Object.values(leg.components));
// The check we're doing here with the groupId belonging to any value from 0 to 4
// is an ugly hack that we had to do because the selections in the state are
// stored with this groupId that the backend when loading an outcome doesn't have
// usually we have 3 groups top, we have added 5 just to be safe but this should
// be improved
const selectionExist = (allSelections) => (selection) => any([0, 1, 2, 3, 4], (groupId) => !!allSelections[`${selection.id}-${groupId}`]);
// ------------------------------------------------------------------------

/*
  Function that given the data for an outcome (collection of legs, usually provided
  from the info stored from previous user selectiions into local storage) checks
  for all his legs if they are still valid and return just those which are ok.

  When a user comes back to BetBuilder after having built an outcome for an event
  this previously built outcome is stored into browser's localeStorage and restored
  for him

  This function will check if all the selections included into the outcome's legs
  are still present in the state for that event, an example of when this will be useful
  is that, if a user built an outcome with some player selection and after a while this player
  is suspended for some reason, we don't want to show to the customer an invalid leg
  containing this non valid selection any more
*/
export default (outcomeData, getState) => {
  const allSelections = getState().betBuilder.selections;
  const validLeg = (leg) => every(legSelections(leg), selectionExist(allSelections));

  return filter(outcomeData, validLeg);
};
