import { createSelector } from 'reselect';

const getOutcomeState = (state) => state.betBuilderOutcome;

const getCreatingOutcome = createSelector(
  getOutcomeState,
  (outcomeState) => outcomeState.creatingOutcome,
);

const getCreatingOutcomeError = createSelector(
  getOutcomeState,
  (outcomeState) => outcomeState.creatingOutcomeError,
);

const getOutcomeAdded = createSelector(
  getOutcomeState,
  (outcomeState) => outcomeState.outcomeAdded,
);

const getDisplayClearOrKeep = createSelector(
  getOutcomeState,
  (outcomeState) => outcomeState.displayClearOrKeep,
);

export {
  getCreatingOutcome,
  getCreatingOutcomeError,
  getOutcomeAdded,
  getDisplayClearOrKeep,
};
