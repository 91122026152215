import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getTutorial } from 'BetBuilder/selectors/tutorial';

import Tutorial from './tutorial';

const TutorialContainer = ({ tutorial, displayTutorial }) => (
  <Tutorial
    tutorial={tutorial}
    displayTutorial={displayTutorial}
  />
);

TutorialContainer.propTypes = {
  tutorial: PropTypes.instanceOf(Array).isRequired,
  displayTutorial: PropTypes.bool.isRequired,
};

export default connect(getTutorial)(TutorialContainer);
