import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'underscore';

const nullSelection = {
  id: '',
};

const compareFunction = (a, b) => (parseInt(a.title, 10) < parseInt(b.title, 10) ? -1 : 1);

const forGroup = (groupId) => (selections) => selections
  .filter((selection) => selection.groupId === groupId)
  .sort(compareFunction);

const find = (id) => (selections) => selections
  .find((selection) => selection.id === parseInt(id, 10))
  || nullSelection;

const selected = (selections) => selections
  .find((selection) => selection.selected)
  || nullSelection;

class Picker extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      group0Selection: compose(selected, forGroup(0))(props.selections),
      group1Selection: compose(selected, forGroup(1))(props.selections),
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ selections, legIndex: legIndexState }) {
    const { legIndex } = this.props;
    /* I do not like accessing legIndex at component level, probably this needs
      some refactor in order to know when we are rendering this component as
      a part of a route change, React Router wont remount the component if it
      was already present on the previous market (for the previous route)

      Thats the reason we need to know if we are coming from a new route
      in order to initialize our internal state with the new component selections
    */
    if (legIndexState !== legIndex) {
      this.setState({
        group0Selection: compose(selected, forGroup(0))(selections),
        group1Selection: compose(selected, forGroup(1))(selections),
      });
    }
  }

  replaceSelectedSelections(selectionId, groupId, selections, replaceSelections) {
    const otherGroupId = 1 - groupId;

    const newSelection = compose(find(selectionId), forGroup(groupId))(selections);
    const otherSelection = (prevState) => prevState[`group${otherGroupId}Selection`];

    this.setState((prevState) => ({
      [`group${groupId}Selection`]: newSelection,
      [`group${otherGroupId}Selection`]: otherSelection(prevState),
    }), () => {
      const { group0Selection, group1Selection } = this.state;
      if (group0Selection === nullSelection || group1Selection === nullSelection) {
        replaceSelections([]);
      } else {
        replaceSelections([
          group0Selection,
          group1Selection,
        ]);
      }
    });
  }

  render() {
    const {
      selections, title, specificInfo, replaceSelections,
    } = this.props;

    const selectionGroups = {
      0: forGroup(0)(selections),
      1: forGroup(1)(selections),
    };

    const groupsSelections = this.state;

    return (
      <div className="bb-correct-score bb-market-component">
        <span className="bb-title-component">
          {title}
        </span>
        <div className="bb-correct-score__scores">
          <div className="bb-correct-score__team-names">
            <div className="bb-correct-score__team-a">{`${specificInfo.teamName0}`}</div>
            <div className="bb-correct-score__team-vs">v</div>
            <div className="bb-correct-score__team-b">{`${specificInfo.teamName1}`}</div>
          </div>
          <div className="bb-correct-score__selector">
            {
              [0, 1].map((groupId) => (
                <select
                  className={`bb-correct-score__team${groupId}`}
                  value={groupsSelections[`group${groupId}Selection`].id}
                  onChange={(e) => this.replaceSelectedSelections(
                    e.target.value, groupId, selections, replaceSelections,
                  )}
                >
                  <option value="">-</option>
                  {
                    selectionGroups[groupId].map((selection) => (
                      <option value={selection.id}>{selection.title}</option>
                    ))
                  }
                </select>
              ))
            }
          </div>
        </div>
      </div>
    );
  }
}

Picker.propTypes = {
  title: PropTypes.string.isRequired,
  selections: PropTypes.instanceOf(Array).isRequired,
  replaceSelections: PropTypes.func.isRequired,
  legIndex: PropTypes.number.isRequired,
  specificInfo: PropTypes.instanceOf(Object).isRequired,
};

export default Picker;
