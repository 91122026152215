import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { makeGetItemChildren } from 'BetBuilder/selectors/navigation';
import List from '../list';

const ListContainer = (props) => <List {...props} />;

ListContainer.propTypes = {
  items: PropTypes.instanceOf(Array).isRequired,
};

const mapStateToProps = () => {
  const getItemChildren = makeGetItemChildren();

  return (state, ownProps) => ({
    items: getItemChildren(state, ownProps),
  });
};

export default connect(mapStateToProps)(ListContainer);
