import { locale } from 'bv-i18n';

export default {
  eventUrl({ eventId, outcomeId }) {
    if (outcomeId) {
      return `/bv_api/bet_builder/events/${eventId}/outcomes/${outcomeId}?locale=${locale()}`;
    }

    return `/bv_api/bet_builder/events/${eventId}?locale=${locale()}`;
  },
};
