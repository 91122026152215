import { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, RenderInBody } from 'bv-components';
import { t } from 'bv-i18n';
import { FormattedPrice } from 'SportsbookCommon';

const tutorialKey = 'betBuilder-tutorial';

const tutorialShownFn = () => {
  try {
    return localStorage.getItem(tutorialKey);
  } catch (err) {
    // If there is any error, for instance if the user do not allow
    // access to localStorage we will consider the tutorial as shown
    return true;
  }
};

const setTutorialAsShown = () => {
  try {
    return localStorage.setItem(tutorialKey, true);
  } catch (err) {
    return undefined;
    // ... Do not nothing
  }
};

class Tutorial extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tutorialShown: tutorialShownFn(),
      checkBoxChecked: false,
    };

    this.closeTutorial = this.closeTutorial.bind(this);
    this.changeCheckBox = this.changeCheckBox.bind(this);
  }

  closeTutorial() {
    const { checkBoxChecked } = this.state;
    if (checkBoxChecked) {
      setTutorialAsShown();
    }

    this.setState((prevState) => ({
      tutorialShown: !prevState.tutorialShown,
    }));
  }

  changeCheckBox() {
    this.setState((prevState) => ({
      checkBoxChecked: !prevState.checkBoxChecked,
    }));
  }

  tutorialElement(index, property) {
    const { tutorial } = this.props;
    return (tutorial[index]
      && tutorial[index][property])
      || '';
  }

  render() {
    const { displayTutorial } = this.props;
    const { tutorialShown, checkBoxChecked } = this.state;

    return (
      displayTutorial && !tutorialShown
        && (
        <RenderInBody>
          <Modal
            iconClosable
            className="bb-market-selector-tutorial"
            onCloseClick={this.closeTutorial}
          >
            <div className="bb-market-selector-tutorial__container">
              <div className="bb-market-selector-tutorial-intro">
                <span className="bb-market-selector-tutorial__title">{t('javascript.bet_builder.app.tutorial.title')}</span>
                <p className="bb-market-selector-tutorial-intro__text">{t('javascript.bet_builder.app.tutorial.description')}</p>
              </div>
              <div className="bb-market-selector-tutorial__markets">
                <span className={`bb-tutorial-market-example bb-${this.tutorialElement(0, 'icon')}-background tutorial-fade-one`}>
                  {this.tutorialElement(0, 'description')}
                </span>
                <span className="bb-tutorial-market-plus bb-tutorial-market-circle" />
                <span className={`bb-tutorial-market-example bb-${this.tutorialElement(1, 'icon')}-background tutorial-fade-two`}>
                  {this.tutorialElement(1, 'description')}
                </span>
                <span className="bb-tutorial-market-plus bb-tutorial-market-circle" />
                <span className={`bb-tutorial-market-example bb-${this.tutorialElement(2, 'icon')}-background tutorial-fade-three`}>
                  {this.tutorialElement(2, 'description')}
                </span>
                <span className="bb-tutorial-market-circle bb-tutorial-market-equals" />
                <button className="bvs-button-sport tutorial-fade-four" type="button">
                  <FormattedPrice price="65/1" />
                </button>
              </div>
              <div className="bb-market-selector-tutorial__started">
                <p className="bb-market-tutorial-started-text">{t('javascript.bet_builder.app.tutorial.make_your_bet')}</p>
                <div className="bvs-button is-secondary get-started-pulse" onClick={this.closeTutorial}>{t('javascript.bet_builder.app.tutorial.get_started')}</div>
                <div className="bb-tutorial-checkbox-container">
                  <label htmlFor="tutorialCheck" className="bb-tutorial-checkbox-label">
                    <input
                      className="bb-tutorial-check"
                      id="tutorialCheck"
                      type="checkbox"
                      defaultChecked={checkBoxChecked}
                      onChange={this.changeCheckBox}
                    />
                    <span className="bb-tutorial-checkmark" />
                    {t('javascript.bet_builder.app.tutorial.dont_show_again')}
                  </label>
                </div>
              </div>
            </div>
          </Modal>
        </RenderInBody>
        )
    );
  }
}

Tutorial.propTypes = {
  tutorial: PropTypes.instanceOf(Array).isRequired,
  displayTutorial: PropTypes.bool.isRequired,
};

export default Tutorial;
