import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import getPrice from 'BetBuilder/selectors/price';

export default (WrappedComponent) => {
  const WithPrice = (props) => {
    const { loading, value } = props;

    return (
      <WrappedComponent
        {...props}
        priceLoadingOrNoValue={loading || !value}
      />
    );
  };

  WithPrice.propTypes = {
    loading: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
  };

  return connect(getPrice)(WithPrice);
};
